import { Suspense, lazy, useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./assets/css/style.scss";
import LoaderGif from "./Components/Loader";
import { GenderContext } from "./Context/GenderThemeContext";
import { ChangeTheme } from "./assets/css/GenderTheme";
import PaymentPopup from "./Components/PaymentPopup";
import ResetPassword from "./Pages/ResetPassword";
import Privacy from "./Pages/privacy";
import Terms from "./Pages/terms";
import MoneyBackGuarantee from "./Pages/moneyBackGuarantee";
import { Typography } from "@mui/material";
import Notfound from "./Components/notfound";
const ThankYou = lazy(() => import("./Pages/ThankYou"));
const DeleteAccountFlow = lazy(() => import("./Pages/DeleteAccountFlow"));
const GatherInformation = lazy(() => import("./Pages/GatherInformation"));

function App() {
  const { gender } = useContext(GenderContext); // Getting gender from context

  useEffect(() => {
    ChangeTheme(gender);
  }, [gender]);

  // remove below code once done
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <Suspense
      fallback={
        <>
          <LoaderGif />
        </>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/male" replace />} />
          <Route path="/male" element={<GatherInformation type={"male"} />} />
          <Route path="/female" element={<GatherInformation type={"female"} />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/money-back" element={<MoneyBackGuarantee />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/delete-account-flow" element={<DeleteAccountFlow />} />
          {/* <Route path="/popup" element={<PaymentPopup />} /> */}
          <Route path="*" element={<Navigate to="/male" replace />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;

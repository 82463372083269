import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";

const dummyPrivacy = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Privacy Policy - Fitness Planner</title>
</head>
<body>
    <h1>Privacy Policy</h1>
    <p>Effective Date: <span id="date">[24/10/2024]</span></p>
    <p>
        Fitness Planner ("we," "us," or "our") is committed to safeguarding your personal data. 
        This Privacy Policy explains how we collect, use, protect, and share your personal data in compliance with the General Data Protection Regulation (GDPR), 
        the Personal Information Protection and Electronic Documents Act (PIPEDA), and other applicable laws.
    </p>

    <h2>1. Information We Collect</h2>
    <ul>
        <li><strong>Basic Identification Data:</strong> Name and email address for account creation and communication.</li>
        <li><strong>Technical Data:</strong> IP address for app functionality and security purposes.</li>
        <li><strong>Financial Data:</strong> Payment information processed via third-party gateways (PayPal and Stripe). We do not store full payment details.</li>
        <li><strong>Behavioral Data:</strong> Interaction with our app (e.g., features used) for improving user experience and analytics.</li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>
        The personal data we collect is used for the following purposes:
    </p>
    <ul>
        <li><strong>Account Management:</strong> To create, maintain, and manage your account.</li>
        <li><strong>Marketing and Advertising:</strong> To send personalized communications and share data with Facebook Ads and Google Ads for targeted campaigns.</li>
        <li><strong>Payment Processing:</strong> To securely process transactions via PayPal and Stripe.</li>
        <li><strong>Service Improvement:</strong> To analyze app usage and improve functionality using Firebase.</li>
        <li><strong>Compliance:</strong> To comply with legal obligations, such as fraud prevention or reporting requirements.</li>
    </ul>

    <h2>3. Legal Basis for Data Processing</h2>
    <p>
        We process your personal data under the following legal bases:
    </p>
    <ul>
        <li><strong>Consent:</strong> For marketing activities, with your explicit consent.</li>
        <li><strong>Contractual Necessity:</strong> To provide services you requested (e.g., payment processing).</li>
        <li><strong>Legal Obligation:</strong> To comply with applicable laws and regulations.</li>
        <li><strong>Legitimate Interests:</strong> To improve our services and ensure security.</li>
    </ul>

    <h2>4. Sharing of Data</h2>
    <p>
        Your personal data is shared with trusted third-party service providers:
    </p>
    <ul>
        <li><strong>Marketing Platforms:</strong> Facebook Ads and Google Ads.</li>
        <li><strong>Payment Gateways:</strong> PayPal and Stripe.</li>
        <li><strong>Backend Services:</strong> Firebase for app performance and analytics.</li>
    </ul>

    <h2>5. Data Retention</h2>
    <p>
        We retain personal data for as long as necessary to fulfill the purposes outlined in this policy or as required by law. 
        Marketing data is retained until you withdraw your consent. Financial data is retained for tax and compliance purposes.
    </p>

    <h2>6. Your Rights</h2>
    <p>
        As a data subject, you have the following rights:
    </p>
    <ul>
        <li>Access your data by contacting us at <a href="mailto:support@fitnessplanner.app">support@fitnessplanner.app</a>.</li>
        <li>Request corrections to inaccurate data.</li>
        <li>Request deletion of your personal data.</li>
        <li>Withdraw consent for data processing activities at any time.</li>
        <li>Request data portability in a commonly used format.</li>
        <li>Lodge complaints with a supervisory authority.</li>
    </ul>

    <h2>7. Security Measures</h2>
    <p>
        We use encryption, secure servers, and regular security audits to protect your data from unauthorized access or misuse.
    </p>

    <h2>8. International Data Transfers</h2>
    <p>
        If personal data is transferred outside of your jurisdiction, we ensure appropriate safeguards, such as Standard Contractual Clauses, are in place.
    </p>

    <h2>9. Contact Us</h2>
    <p>
        Address: 59 Baltic St, Richmond Hill, ON, Canada, L4B4G9<br>
        Email: <a href="mailto:support@fitnessplanner.app">support@fitnessplanner.app</a>
    </p>
</body>
</html>
`;

const Privacy = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}cms/privacy-policy`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      setLoading(false);
      if (data.status === 200) {
        // console.log(decodeURIComponent(data.data?.cmsPage?.content));
        setData(decodeURIComponent(data.data?.cmsPage?.content));
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box sx={{ backgroundColor: "#fff", minHeight: "100vh", height: "100%" }}>
      <Header />
      {!loading && (
        <Box
          sx={{ px: 2 }}
          dangerouslySetInnerHTML={{
            __html: data ? data : dummyPrivacy,
          }}
        />
      )}
    </Box>
  );
};

export default Privacy;

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";

const dummyTerms = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms and Conditions - Fitness Planner</title>
</head>
<body>
    <h1>Terms and Conditions</h1>
    <p>Effective Date: <span id="date">[24/10/2024]</span></p>

    <h2>1. Introduction</h2>
    <p>
        Welcome to Fitness Planner! By accessing or using our services, you agree to these Terms and Conditions ("Terms"). 
        If you do not agree, do not use our services.
    </p>

    <h2>2. User Obligations</h2>
    <ul>
        <li>You agree to provide accurate and complete information when creating an account.</li>
        <li>You must not engage in any activity that disrupts or interferes with our services.</li>
        <li>All use of our services must comply with applicable laws and regulations.</li>
    </ul>

    <h2>3. Payments</h2>
    <p>
        Payments are processed via PayPal and Stripe. You are responsible for ensuring your payment information is accurate. 
        We are not liable for issues caused by third-party payment providers.
    </p>

    <h2>4. Intellectual Property</h2>
    <p>
        All content, including designs, logos, and software, is owned by Fitness Planner. Unauthorized use is prohibited.
    </p>

    <h2>5. Limitation of Liability</h2>
    <p>
        Fitness Planner is not responsible for indirect, incidental, or consequential damages arising from your use of our services. 
        Our liability is limited to the maximum extent permitted by law.
    </p>

    <h2>6. Health and Safety Disclaimer</h2>
    <p>
        Before committing to any workout or exercise, consult with your doctor or a qualified healthcare professional to ensure it is safe for you. 
        Fitness Planner is not responsible for any injuries, health issues, or damages that may occur as a result of using our services. 
        By using our platform, you agree that you undertake exercises at your own risk.
    </p>

    <h2>7. Termination</h2>
    <p>
        We may terminate or suspend your account if you violate these Terms or misuse our services.
    </p>

    <h2>8. Governing Law</h2>
    <p>
        These Terms are governed by the laws of Ontario, Canada. Disputes shall be resolved in the courts of Ontario.
    </p>

    <h2>9. Changes to Terms</h2>
    <p>
        We reserve the right to modify these Terms at any time. Continued use of our services constitutes acceptance of updated Terms.
    </p>

    <h2>10. Contact Us</h2>
    <p>
        Address: 59 Baltic St, Richmond Hill, ON, Canada, L4B4G9<br>
        Email: <a href="mailto:support@fitnessplanner.app">support@fitnessplanner.app</a>
    </p>
</body>
</html>
`;

const Terms = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}cms/terms-and-conditions`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      setLoading(false);
      if (data.status === 200) {
        // console.log(decodeURIComponent(data.data?.cmsPage?.content));
        setData(decodeURIComponent(data.data?.cmsPage?.content));
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box sx={{ backgroundColor: "#fff", minHeight: "100vh", height: "100%" }}>
      <Header />
      {!loading && (
        <Box
          sx={{ px: 2 }}
          dangerouslySetInnerHTML={{
            __html: data ? data : dummyTerms,
          }}
        />
      )}
    </Box>
  );
};

export default Terms;

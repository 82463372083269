import React, { useEffect, useState } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
// import paypalLogo from "../../assets/PngImages/paypal_logo.png";
import {
  Snackbar,
  SnackbarContent,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getCookie } from "../../Utils/cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const PaypalOption = ({ currentPlan, goToNextSlide }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState("");

  let updatedData = getCookie('data')

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY, // Replace with your client ID
    "intent": "subscription",
    "vault": true,
    "currency": "USD",
  };

  const handleClose = () => {
    setOpen("");
  };

  useEffect(() => {
    // console.log("currentPlan", currentPlan)
  }, [currentPlan])

  return (
    <Stack position={"relative"}>
      <style>
        {`.paypal-button{
            border-radius: 40px;
            width: ${isSmallScreen ? "220px" : "290px"};
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border: none;
            padding: 0;
            cursor: pointer;
        }`}
      </style>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor:
              open === "Payment Successful"
                ? theme.palette.success.main
                : theme.palette.error.main,
          }}
          message={open}
        />
      </Snackbar>

      <PayPalScriptProvider options={initialOptions}>
        <Stack
          position={"absolute"}
          zIndex={999}
          left={{ sm: 62, xs: 35 }}
          top={4}
          width={"40px"}
        >
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/paypal_logo.png`}
            alt="logo"
            style={{ width: "100%", height: "auto" }}
          />
        </Stack>

        <PayPalButtons
          createSubscription={async (data, actions) => {
            return actions?.subscription?.create({
              'plan_id': currentPlan?.paypalPlanId,
              'subscriber': {
                'name': {
                  'given_name': updatedData?.name, // First name
                  'surname': "", // Last name
                },
                'email_address': updatedData?.email.toLowerCase(), // User's email
              },
            })
          }}

          onApprove={async (data, actions) => {
            // console.log("You have successfully subscribed to :", data)
            toast.success("Payment successful!");
            setTimeout(() => goToNextSlide(updatedData), 1500);
          }}

          className="paypal-button"
          fundingSource={FUNDING.PAYPAL}
          style={{ height: 55, borderRadius: 40, layout: "vertical" }}
        />
      </PayPalScriptProvider>
    </Stack>
  );
};

export default PaypalOption;
